.search-input-wrapper {
  width: 400px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  background: #f5f5f5;
  height: 30px;
  font-size: 12px;
  padding: 0 8px;
  border: none;
  border-bottom: 1px solid $borderColor;
  border-radius: 3px;

  outline: none;
  box-sizing: border-box;

  &:focus {
    border-bottom: 1px solid mat-color($logic-app-primary);
  }
}

.dark-theme {
  .search-input {
    background: #343434;
    color: #ffffffc1;

    &::placeholder {
      color: #969696c1;
    }
  }
}

.mat-select.small-text .mat-select-value-text {
  font-size: 12px;
  color: #666;
}
