/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100vh;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
  // font-family: 'Piazzolla', serif !important;
  height: calc(100vh - 64px);
}



// * {
//   font-family: Roboto, 'Helvetica Neue', sans-serif !important;
// }
