@import "~@angular/material/theming";
@import "./colors";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$logic-app-primary: mat-palette($mat-amber);
$logic-app-accent: mat-palette($mat-green);

// The warn palette is optional (defaults to red).
$logic-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$logic-app-theme: mat-light-theme($logic-app-primary, $logic-app-accent, $logic-app-warn);

// Define an alternate dark theme.
$dark-primary: mat-palette($mat-amber);
$dark-accent: mat-palette($mat-gray);
$dark-warn: mat-palette($mat-red);
$logic-dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

.dark-theme {
  @include angular-material-theme($logic-dark-theme);

  .mat-drawer-content,
  .mat-snack-bar-container {
    background: $darkBg !important;
  }

  .page-centered {
    background: $darkBg !important;
  }

  .mat-snack-bar-container {
    color: white !important;
  }
}

.dark-theme {
  .mat-menu-panel {
    background: $midBg !important;
  }

  .mat-dialog-container {
    background: $midBg !important;
  }

  .bg-gray-light {
    background: $darkBg !important;
  }

  qbit-table-filters .label {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  #risk
    div.highcharts-data-labels.highcharts-series-0.highcharts-solidgauge-series.highcharts-tracker
    > div
    > span
    > div
    > span,
  #risk svg > g.highcharts-axis-labels.highcharts-yaxis-labels > text:nth-child(1) {
    color: $textLight !important;
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #ffc107 !important;
  }
}

.mat-drawer-content,
.page-centered {
  background: $bg;
}

@include angular-material-theme($logic-app-theme);

$primary: mat-color($logic-app-primary);
$accent: mat-color($logic-app-accent);

.mat-success {
  background-color: green;
  color: #4caf50;
}

.col-green,
.col-running,
.col-success {
  color: #4caf50 !important;
}

.col-red,
.col-stopped,
.col-error,
.col-errored {
  color: #f44336 !important;
}

.col-blue,
.col-info,
.col-stopped {
  color: #2196f3 !important;
}

.col-warning,
.col-yellow {
  color: #f3c921 !important;
}

.col-warning-light {
  color: lighten(#f3c921, 10) !important;
}
.col-success-light {
  color: lighten(#4caf50, 10) !important;
}
.col-error-light {
  color: lighten(#f44336, 10) !important;
}
.col-info-light {
  color: lighten(#2196f3, 10) !important;
}

// Backgrounds
.bg-warning-light {
  background: lighten(#f3c921, 10) !important;
}
.bg-success-light {
  background: lighten(#4caf50, 10) !important;
}
.bg-error-light {
  background: lighten(#f44336, 10) !important;
}
.bg-info-light {
  background: lighten(#2196f3, 10) !important;
}

.svg .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: inherit;
}

.white-spinner {
  stroke: white !important;
}

.primary-spinner {
  stroke: $primary !important;
}

.black-spinner {
  stroke: black !important;
}

.bordered-bottom {
  border-bottom: thin solid $borderColor;
}

.bordered-top {
  border-top: thin solid $borderColor;
}

.bordered {
  border: thin solid $borderColor;
  border-radius: 3px;
}

.dark-theme {
  .bordered-bottom,
  .bordered-top,
  .bordered,
  .card-header {
    border-color: $borderColorDark;
  }

  td,
  th {
    border-color: $borderColorDark !important;
  }
}

.border-radius-3 {
  border-radius: 3px;
}

.toolbar-white,
.bg-white {
  background: white;
}

.bg-gray-light {
  background: $bg;
}

* {
  outline: none !important;
}

// this imports must go at the end of the file
// If not it will not override styles
@import "./breakpoints";
@import "./kbd";
@import "./structure";
@import "./statuses";
@import "./buttons";
@import "./text";
@import "./inputs";
@import "./lists";
@import "./overrides";
