@media  (max-width: 640px) {
    .xs-hide {
        display: none !important;
    }
}

@media  (max-width: 768px) {
    .md-hide {
        display: none !important;
    }
}

@media  (max-width: 1024px) {
    .lg-hide {
        display: none !important;
    }
}

@media  (max-width: 1280px) {
    .xlg-hide {
        display: none !important;
    }
}
